<template>
  <div
    class="country-card"
    :class="{
      'loading': isLoadingCard,
      'disabled': Boolean(messageDisabledNumber),
    }"
  >
    <div class="country-card__main">
      <div class="country-card__image">
        <client-only>
          <nuxt-img
            :src="String(item.icon_id)"
            width="54px"
            height="54px"
            fit="cover"
            loading="lazy"
          />
        </client-only>
      </div>
      <div class="country-card__name">
        {{ item.name }}
      </div>
      <div class="country-card__count">
        {{ countLabel }}
      </div>
      <div class="country-card__button">
        <button
          class="btn btn-primary"
          @click="buyNumber"
        >
          <span class="--text">{{ priceLabel }}</span>
          <span class="--hover">{{ buttonLabel }}</span>
        </button>
      </div>
    </div>
    <div class="country-card__footer"></div>

    <div v-if="isLoadingCard" class="country-card__loading">
      {{ $t('country.loading_buy_message') }}
    </div>

    <template v-if="messageDisabledNumber">
      <ServiceDisabledNumber
        :message="messageDisabledNumber"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import type { ComputedRef } from 'vue';
import { getNumberBitDepth } from '~/utils/numberFormat';
import { useBuyRentNumberStore } from '~/store/buyRentNumber';

const ServiceDisabledNumber = defineAsyncComponent(() => import('~/components/common/ServiceDisabledNumber.vue'));

const props = defineProps({
  item: {
    type: Object,
    default: () => {
      return {}
    }
  }
});
const emits = defineEmits(['buy', 'rent']);
const $i18n = useI18n();

const buyRentNumber = useBuyRentNumberStore();
const isLoadingCard: ComputedRef<boolean> = computed(() => {
  const filterVal: string = [props.item?.country_external_id, props?.item?.service_external_id].join('__');
  return Boolean(buyRentNumber.loadingCards.includes(filterVal));
});

const isOpenFooter: Ref<boolean> = ref(false);
const changeOpenFooter = (_val: boolean) => {
  isOpenFooter.value = _val;
};

const buyNumber = async () => {
  emits('buy', props.item);
};

const countLabel: ComputedRef<any> = computed(() => {
  const count = Number.parseFloat(props.item?.count || '0');
  if (count <= 0) {
    return $i18n.t('common.few')
  }
  return [getNumberBitDepth(count, 0, '.'), $i18n.t('common.pcs')].join(' ')
});
const priceLabel: ComputedRef<any> = computed(() => {
  const price = getNumberBitDepth(Number.parseFloat(props.item?.price || 0), 'auto');
  return [price, '₽'].join('')
});
const buttonLabel: ComputedRef<any> = computed(() => {
  return $i18n.t('common.buy')
});

const messageDisabledNumber: ComputedRef<string> = computed(() => {
  if (String(props.item?.country_external_id) === '0') {
    return $i18n.t('country.error_message_russian_numbers_long')
  }
  return ""
});
</script>

<style scoped lang="scss">
.country-card {
  background: hsla(var(--white)/1);
  border-radius: 8px;
  cursor: pointer;
  min-height: 64px;
  position: relative;
  border: 1px solid hsla(var(--black)/0.1);
  box-shadow: 0px 2px 4px 0px hsla(var(--black)/0.07);

  &:not(.disabled):hover {
    background: hsla(var(--primary-bg)/0.2);

    .country-card__button .--text {
      opacity: 0;
    }
    .country-card__button .--hover {
      opacity: 1;
    }
  }
  &.loading {
    position: relative;
    overflow: hidden;
    pointer-events: none;
  }
}
.country-card__main {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 5px;
  box-sizing: border-box;
}
.country-card__image {
  width: 54px;
  height: 54px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
.country-card__name {
  flex: 1;
  font-size: 18px;
  line-height: 1.4;
  font-weight: 500;
  color: hsla(var(--black));
}
.country-card__count {
  font-size: 16px;
  line-height: 1.2;
  color: hsla(var(--black)/0.6);
}
.country-card__button {
  .btn {
    padding: 4px 12px;
    border-radius: 6px;
    position: relative;
    min-width: 80px;

    font-size: 15px;
    line-height: 1.2;
    font-weight: 500;
    text-align: center;
  }

  .--text {
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
  }
  .--hover {
    opacity: 0;
  }
}

.country-card__loading {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background: hsla(var(--white)/0.6);
  border: 1px solid hsla(var(--primary-bg)/1);
  border-radius: 8px;
  padding: 0 20px;
  backdrop-filter: blur(5px);

  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
  color: hsla(var(--primary-bg)/1);
}
</style>
