<template>
  <div class="service-inverse-v2-block">
    <div v-if="false" class="service-inverse-v2-block__section">
      <div class="service-inverse-v2-block__tabs">
        <div :class="{'active': Boolean(state.type === 'buy')}" @click="() => changeType('buy')">Активация</div>
        <div :class="{'active': Boolean(state.type === 'rent')}" @click="() => changeType('rent')">Аренда</div>
      </div>
    </div>

    <div class="service-inverse-v2-block__section">
      <div class="--header">
        <img class="--header-icon" src="~/assets/img/svg/service-inverse/service-select.svg"/>
        <div class="--header-title">Выбор сервиса</div>
      </div>
      <div class="service-inverse-v2-block__services">
        <div
          v-for="(service) in buyRentNumberStore.serviceListPopular"
          :key="`service-${ service.id }`"
          :class="{'selected': Boolean(String(service.id) === String(buyRentNumberStore.serviceSelected))}"
          @click="() => changeServiceSelected(service)"
        >
          <nuxt-img
            :src="String(service.icon_id)"
            width="72px" height="72px"
            loading="lazy"
            fit="cover"
          />
          <span>{{ service.name }}</span>
        </div>
      </div>
      <div class="service-inverse-v2-block__select">
        <Multiselect
          v-model="serviceSelectedItem"
          :options="serviceListOptions"
          :internal-search="false"
          label="name"
          track-by="name"

          placeholder="Поиск"
          selectLabel=""

          @select="changeServiceSelected"
          @open="() => changeOpenMultiselect(true)"
          @close="() => changeOpenMultiselect(false)"
        >
          <template #option="{ option }">
            <div class="--option">
              <nuxt-img
                :src="String(option.icon_id)"
                width="64px" height="64px"
                fit="cover"
                loading="lazy"
              />
              <span>{{ option.name }}</span>
            </div>
          </template>
          <template #singleLabel="{ option }">
            <div class="--option">
              <nuxt-img
                :src="String(serviceSelectedItem?.icon_id)"
                width="64px" height="64px"
                fit="cover"
                loading="lazy"
              />
              <span>{{ serviceSelectedItem?.name }}</span>
            </div>
          </template>
        </Multiselect>
      </div>
    </div>

    <div class="service-inverse-v2-block__section --countries">
      <div class="--header">
        <img class="--header-icon" src="~/assets/img/svg/service-inverse/country-select.svg"/>
        <div class="--header-title">Выбор страны</div>
      </div>
      <div class="service-inverse-v2-block__countries-root scroll-customize">
        <div
          class="service-inverse-v2-block__countries"
          :class="{
            '--list': Boolean(!buyRentNumberStore.countriesListLoading && buyRentNumberStore.countriesList.length > 0)
          }"
        >
          <template v-if="!buyRentNumberStore.countriesListLoading && buyRentNumberStore.countriesList.length > 0">
            <CountryItem
              v-for="(country) in buyRentNumberStore.countriesList"
              :key="`country-${ country.id }`"
              :item="country"

              @buy="buyNumber"
              @rent="rentNumber"
            />
          </template>
          <template v-if="!buyRentNumberStore.countriesListLoading && buyRentNumberStore.countriesList.length <= 0">
            <div class="service-inverse-v2-block__message">По вашему запросу ничего не найдено</div>
          </template>
          <template v-if="buyRentNumberStore.countriesListLoading">
            <div class="skeleton --loading-card"/>
            <div class="skeleton --loading-card"/>
            <div class="skeleton --loading-card"/>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { type IServiceItem, useBuyRentNumberStore } from '~/store/buyRentNumber';
import type { ComputedRef } from 'vue';
import Multiselect from 'vue-multiselect';
import CountryItem from '~/components/cms/service-inverse-block/CountryItemV2.vue';

const {
  $state: state,
  changeType,

  actionBuyNumber
} = useBuyRentNumberStore();
const buyRentNumberStore = useBuyRentNumberStore();

const isOpenMultiselect: Ref<boolean> = ref(false);
const changeOpenMultiselect = (val: boolean) => {
  isOpenMultiselect.value = val;
};

const serviceListOptions: ComputedRef<(IServiceItem | null)[]> = computed(() => {
  if (!isOpenMultiselect.value) {
    return [];
  }
  return [...buyRentNumberStore.serviceList];
});
const serviceSelectedItem: ComputedRef<IServiceItem | null> = computed(() => {
  if (!buyRentNumberStore.serviceSelected) {
    return null;
  }
  return buyRentNumberStore.serviceList.find((t: any) => Boolean(String(t.id) === String(buyRentNumberStore.serviceSelected))) || null;
});
const changeServiceSelected = async (option: any) => {
  await buyRentNumberStore.changeBuyServicesSelected(String(option.id));
};

const buyNumber = async (country: any) => {
  await actionBuyNumber({
    serviceId: String(country?.service_external_id),
    countryId: String(country?.country_external_id),
  });
};
const rentNumber = async (country: any) => {
};

const initMaxHeight = async () => {

};

onMounted(async () => {
  await initMaxHeight();
});
</script>

<style lang="scss">
.service-inverse-v2-block {
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex: 1;
}

.service-inverse-v2-block__tabs {
  display: flex;
  padding: 5px;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px 0px #0000000D;
  background-color: hsla(var(--white)/1);
  border-radius: 10px;
  overflow: hidden;
  position: relative;

  & > * {
    cursor: pointer;
    padding: 10px 16px;
    box-sizing: border-box;
    border-radius: 6px;
    flex: auto;

    font-size: 18px;
    line-height: 1;
    font-weight: 500;
    text-align: center;
    color: hsla(var(--dark-gray-tx)/1);

    &.active {
      background-color: hsla(var(--primary-bg)/1);
      color: hsla(var(--primary-tx)/1);
      box-shadow: 0px 4px 16px 0px #00000026;
    }

    &:hover {
      background-color: hsla(var(--primary-bg)/0.8);
      color: hsla(var(--primary-tx)/1);
    }
  }
}

.service-inverse-v2-block__section {
  padding: 12px;
  border-radius: 10px;
  box-sizing: border-box;
  background: hsla(var(--main-section-bg)/1);
  position: relative;

  .--header {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    gap: 8px;
  }
  .--header-icon {
    opacity: 0.6;
  }
  .--header-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: -0.02em;
    color: hsla(var(--black)/1);
  }

  &.--countries {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 240px;
  }
}

.service-inverse-v2-block__services {
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr 1fr;

  & > * {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 6px;
    border-radius: 10px;
    box-sizing: border-box;
    background: hsla(var(--white)/1);
    cursor: pointer;
    border: 1px solid transparent;
    box-shadow: 0px 2px 4px 0px hsla(0, 0%, 0%, 0.07);

    font-size: 15px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -0.02em;
    color: hsla(var(--black)/1);

    img {
      width: 34px;
      height: 34px;
      border-radius: 4px;
      object-fit: contain;
    }

    span {
      flex: auto;
    }

    &:hover {
      background: hsla(var(--primary-bg)/0.2);
      color: hsla(var(--primary-bg)/1);
    }

    &.selected {
      background: hsla(var(--primary-bg)/0.2);
      //color: hsla(var(--primary-bg)/1);
      border-color: hsla(var(--primary-bg)/1);
    }
  }
}

.service-inverse-v2-block__countries-root {
  position: relative;
  flex: 1;
  overflow: auto;
  margin-right: -10px;
  padding-right: 10px;
  box-sizing: border-box;
}

.service-inverse-v2-block__countries {
  display: flex;
  flex-direction: column;
  gap: 6px;
  position: absolute;
  top: 0;
  left: 0;
  right: 10px;
  height: 100%;

  .--loading-card {
    height: 64px;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
  }
}

.service-inverse-v2-block__select {
  margin-top: 10px;

  .--option {
    gap: 10px;
    display: flex;
    align-items: center;
    background: transparent;

    font-size: 17px;
    font-weight: 500;
    line-height: 1.4;

    img {
      width: 32px;
      height: 32px;
      object-fit: cover;
      border-radius: 4px;
    }

    span {
      flex: 1;
    }
  }

  .multiselect__tags {
    display: flex;
    align-items: center;
    min-height: 56px;
  }
}

.service-inverse-v2-block__message {

}

@media (max-width: 1399px) {
  .service-inverse-v2-block__services {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 1023px) {
  .service-inverse-v2-block__section {
    padding: 4px;

    .--header {
      padding: 8px 10px;
      padding-bottom: 0;
    }
  }
}
</style>
